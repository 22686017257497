<template>
  <div class="flex flex-nowrap gap-1">
    <UiButton
      v-if="settings.facebook"
      :to="settings.facebook"
      target="_blank"
      rel="noopener noreferrer"
      type="secondary"
      icon="Facebook"
    />
    <UiButton
      v-if="settings.linkedin"
      :to="settings.linkedin"
      target="_blank"
      rel="noopener noreferrer"
      type="secondary"
      icon="Linkedin"
    />
    <UiButton
      v-if="settings.tiktok"
      :to="settings.tiktok"
      target="_blank"
      rel="noopener noreferrer"
      type="secondary"
      icon="Tiktok"
    />
    <UiButton
      v-if="settings.instagram"
      :to="settings.instagram"
      target="_blank"
      rel="noopener noreferrer"
      type="secondary"
      icon="Instagram"
    />
    <UiButton
      v-if="settings.twitter"
      :to="settings.twitter"
      target="_blank"
      rel="noopener noreferrer"
      type="secondary"
      icon="X"
    />
    <UiButton
      v-if="settings.youtube"
      :to="settings.youtube"
      target="_blank"
      rel="noopener noreferrer"
      type="secondary"
      icon="YouTube"
    />
  </div>
</template>

<script lang="ts" setup>
const {settings} = useSofieSettings();
</script>
